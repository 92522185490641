<template>
    <v-card-text>
        <v-form @submit.prevent="createSerology" disabled ref="form">
            <v-container>
                <p style="font-weight:bold" v-if="((this.$store.state.profile_id == 5))">Técnico de Hemoba:</p>
                    <v-row v-if="((this.$store.state.profile_id == 5))">
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select 
                            :rules="formRules"
                            :items="hemoba_profiles"
                            color="#256936" 
                            v-model="serologyForm.hemoba_profiles_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Tipo de Perfil">
                            </v-select>
                        </v-col>
                    </v-row>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#256936" 
                        :rules="formRules" 
                        label="Registro"
                        outlined
                        v-model="serologyForm.register" 
                        maxlength="14" 
                        v-mask="'##############'"
                        dense></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#256936" 
                        :rules="formRules" 
                        label="Nº do Laudo/AAAA"
                        outlined
                        v-model="serologyForm.report_number" 
                        dense></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Data da Coleta de Amostra"
                        type="date" 
                        outlined 
                        v-model="serologyForm.sample_date"
                        dense></v-text-field>
                    </v-col>
                </v-row>
        
                <!-- <p style="color:#b0b0a9">Tipo de Laudo:</p>
                <v-row style="padding-left:10px">
                    <v-radio-group :rules="formRules" v-model="serologyForm.type_report_id">
                        <v-radio label="Definitivo" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Provisório" 
                        value="2" 
                        color="#086318"
                        ></v-radio>
                    </v-radio-group>
                </v-row> -->
                <!-- <br>
                <p style="color:#b0b0a9">Resultado dos Exames Laboratoriais:</p> -->
                    <p style="color:#b0b0a9">Grupo Sanguíneo:</p>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#256936" 
                        :rules="formRules" 
                        label="ABO"
                        outlined
                        v-model="serologyForm.abo" 
                        dense></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#256936" 
                        :rules="formRules" 
                        label="Rh"
                        outlined
                        v-model="serologyForm.rh" 
                        dense></v-text-field>
                    </v-col>
                </v-row>

                <p class="subtitle" style="color:#b0b0a9;">Sorologia:</p>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.sifilis_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Sífilis">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.chagas_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Chagas">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.aghbs_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="AgHBs">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.anti_hbc_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="ANTI-HBc">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.hcv1_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HCV (1ª Metodologia)">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.hcv2_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HCV (2ª Metodologia)">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.hcv_nat_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HCV NAT">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.anti_hbs_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="ANNTI-HBs">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.htlv_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HTLV">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.hiv1_2_1_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HIV1+2 (1ª Metodologia)">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.hiv1_2_2_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HIV1+2 (2ª Metodologia)">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.hiv_nat_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="HIV NAT">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.t_plasmosis_lgg_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Toxoplasmose lgG">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.t_plasmosis_lgm_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Toxoplasmose lgM">
                        </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.cmv_lgg_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="CMV lgG">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="d-flex" cols="12" sm="3">
                        <v-select 
                        :rules="formRules"
                        :items="status_serology"
                        color="#256936" 
                        v-model="serologyForm.cmv_lgm_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="CMV lgM">
                        </v-select>
                    </v-col>
                </v-row>
                <p style="font-weight:bold">Anexos:</p>
                <template>
                        <v-data-table
                            :headers="headers"
                            :items="arquivos"
                            :items-per-page="5"
                            disabled
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon 
                                size="24"
                                class="icon"
                                color="blue" 
                                align-item="center"
                                @click="downloadAttachments(item.id)">mdi-download-circle
                                </v-icon>
                            </template>
                        </v-data-table>
                    </template>

                <!-- RELATÓRIO HTML -->
                <div v-show="showReport" id="print-sticker">
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;" >
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 30%;">
                                <img src="./../../../../assets/cet.png" style="width: 100px;">
                            </th>
                            <th style=" border: 2px solid #000; padding: 4px">
                                <p style="padding: 0px; font-size: 24px; margin: 0;">SISTransplantes</p>
                                <p style="padding: 0px; font-size: 16px; margin: 0;">Relatório de Sorologia</p>  
                            </th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Paciente Doador: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.donor.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Registro: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.register}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Nº do Laudo: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.report_number}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Tipo de Exame: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.type_reports.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Data de Coleta da Amostra: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.sample_date_new_format}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Grupo Sanguíneo ABO: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.abo}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Grupo Sanguíneo RH: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.serologyForm.rh}}</th>
                        </tr>
                    </table>
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;">
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 5%;" colspan="2">
                                <p style="padding: 0px; font-size: 18px; margin: 0;">Sorologia</p> 
                            </th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Sífilis: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.sifilis_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Chagas: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.chagas_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">AgHBs: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.anti_hbs_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">ANTI-HBc: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.anti_hbc_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HCV (1ª Metodologia): </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.hcv1_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HCV (2ª Metodologia): </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.hcv2_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HCV NAT: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.hcv_nat_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">ANNTI-HBs: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.anti_hbs_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HTLV: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.htlv_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HIV1+2 (1ª Metodologia): </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.hiv1_2_1_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HIV1+2 (2ª Metodologia): </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.hiv1_2_2_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">HIV NAT: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.hiv_nat_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Toxoplasmose IgG: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.t_plasmosis_lgg_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Toxoplasmose IgM: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.t_plasmosis_lgm_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">CMV IgG: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.cmv_lgg_status.name}}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">CMV IgM: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ this.serologyForm.cmv_lgm_status.name}}</th>
                        </tr>
                    </table>
                    <div style="padding: 38px"></div>
                    <div style="display: flex; justify-content: center;">
                        <img :src="url" alt="assinatura" width="230px" display="block" margin="auto">
                    </div>
                    <hr style="border: 1px solid #000; width:50%;">
                    <p style="text-align: center; padding: 0px; margin: 0;" v-if="((this.serologyForm.users.profile_id == 5))">{{ this.serologyForm.hemoba_profiles.name }}</p>
                    <p style="text-align: center; padding: 0px; margin: 0;" v-if="((this.serologyForm.users.profile_id != 5))">Assinatura de Responsável</p>
                </div>
                <br>
            <div class="buttons">
                <v-row>
                    <v-btn class="rounded-lg" elevation="0" type="submit" color="green" @click="$router.push('/doador')">
                        <span class="textBtn">Sair</span>
                    </v-btn><p class="space-personalized"></p>
                    <v-btn class="rounded-lg" elevation="0" color="blue" @click="mountReport()">
                        <v-icon dark color="white">mdi-printer</v-icon>
                        <span class="textBtn">  Imprimir Sorologia</span>
                    </v-btn>
                </v-row>
            </div> 
            </v-container>
        </v-form>
    </v-card-text>

</template>
  
<script>
import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"
import fileDownload from "js-file-download";

export default {
    data() {
        return {
            url:"",
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            serologyForm: {
                sample_date_new_format: ""
            },
            headers: [
                {
                    text: "Descrição",
                    align: "center",
                    value: "attachment_name",
                },
                {   
                    text: "Download", 
                    value: "actions", 
                    align: "center",
                    sortable: false 
                },
            ],
            selectedDonor: [],
            arquivos: [],
            page: 1,
            donor_id: "",
            loading: true,
            options: {},
            donor: [],
            unity_idSelected: "",
            status_serology: [],
            message: "",
            snackbar: false,
            unity: "",
            user_id: "",
            donorID: "",
            showReport: false,
            print_load: false,
            space_load: false,
            formRules: [
             (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    created() {
        axios.get(`/hemoba_profiles`).then((response) => {this.hemoba_profiles = response.data.data;});
        axios.get(`/serology_status`).then((response) => {this.status_serology = response.data.data;});
        this.donorID = this.$route.query.item.id
        const id = this.donorID
            axios.get(`/serology/list/${id}`).then((response) => {
                this.serologyForm = response.data[0]
                this.user_id = this.serologyForm.users.id
                this.serologyForm.sample_date = FormatDate.dataAtualFormatada(this.serologyForm.sample_date);
                this.serologyForm.hemoba_profiles_id = parseInt(this.serologyForm.hemoba_profiles_id);
                this.serologyForm.aghbs_id = parseInt(this.serologyForm.aghbs_id);
                this.serologyForm.anti_hbc_id = parseInt(this.serologyForm.anti_hbc_id);
                this.serologyForm.anti_hbs_id = parseInt(this.serologyForm.anti_hbs_id);
                this.serologyForm.chagas_id = parseInt(this.serologyForm.chagas_id);
                this.serologyForm.cmv_lgg_id = parseInt(this.serologyForm.cmv_lgg_id);
                this.serologyForm.cmv_lgm_id = parseInt(this.serologyForm.cmv_lgm_id);
                this.serologyForm.donor_id = parseInt(this.serologyForm.donor_id);
                this.serologyForm.hcv1_id = parseInt(this.serologyForm.hcv1_id);
                this.serologyForm.hcv2_id = parseInt(this.serologyForm.hcv2_id);
                this.serologyForm.hcv_nat_id = parseInt(this.serologyForm.hcv_nat_id);
                this.serologyForm.hiv1_2_1_id = parseInt(this.serologyForm.hiv1_2_1_id);
                this.serologyForm.hiv1_2_2_id = parseInt(this.serologyForm.hiv1_2_2_id);
                this.serologyForm.hiv_nat_id = parseInt(this.serologyForm.hiv_nat_id);
                this.serologyForm.htlv_id = parseInt(this.serologyForm.htlv_id);
                this.serologyForm.t_plasmosis_lgg_id = parseInt(this.serologyForm.t_plasmosis_lgg_id);
                this.serologyForm.t_plasmosis_lgm_id = parseInt(this.serologyForm.t_plasmosis_lgm_id);
                this.serologyForm.sample_date = FormatDate.dataAtualFormatada(this.serologyForm.sample_date);
                this.serologyForm.sample_date_new_format = FormatDate.dataAtualFormatadaRelatorio(this.serologyForm.sample_date);
                this.serologyForm.sifilis_id = parseInt(this.serologyForm.sifilis_id)});
                this.loadTable()
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;
        },

        mountReport(){
            axios.get(`/unities`).then((response) => { this.unity = response.data.data;});
                axios.get(`/donor`).then((response) => {this.donor = response.data.data;});
                        axios.get(`/digital_signatures/get/${this.user_id}`, {responseType: "arraybuffer"}).then((response) => {
                            const blob = new Blob([response.data], { type: "image/png" });
                                this.url = URL.createObjectURL(blob);
                                setTimeout(() => {
                                    this.print()
                                }, 3000);
                        });
            
        },

        loadTable(){
            axios.get("/attachments").then((response) => {
                this.arquivos = response.data.data;
                this.arquivos = this.arquivos.filter((x) => x.donor_id == this.serologyForm.donor_id);
            });
        },

        downloadAttachments(id) {
            axios.get(`/attachments/download/${id}`, {
                responseType:"blob",
            }).then((response) => {
                let fileType = "";
                if  (response.data.type == "application/pdf") fileType = "pdf";
                if  (response.data.type == "image/png") fileType = "png";
                if  (response.data.type == "image/jpeg") fileType = "jpeg";

                fileDownload(response.data, `doc.${fileType}`);
            });
        },

        print() {
            this.$htmlToPaper("print-sticker")
        },

        openCreateDoador() {
            this.dialog = true;
        },
    },

    mounted() {
        this.indoDataTable();
    },
};
</script>
  
<style scoped>
.divHeader{
    height: 112px;
    width: 901px;
    font-weight: bold;
    text-align: center;
    border:solid 5px;
}
.logoIMG{
    height: 85px;
    width: 211px;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
  